import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Https } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class CodeService {
  constructor(private http: Https) {}

  public getLanguageCodeList() {
    const params = new HttpParams().set('code', 'Language');
    return this.http.get(`${environment.baseUrl}/codeTables`, params, { skipAuth: true });
  }

  public getGlobeMenuCodeList() {
    const params = new HttpParams().set('code', 'GlobeMenu');
    return this.http.get(`${environment.baseUrl}/codeTables`, params, { skipAuth: true });
  }

  public getCompanyTypeCodeList() {
    const params = new HttpParams().set('code', 'CompanyType');
    return this.http.get(`${environment.baseUrl}/codeTables`, params, { skipAuth: true });
  }

  public getPlukonDomainCodeList() {
    const params = new HttpParams().set('code', 'PlukonDomain');
    return this.http.get(`${environment.baseUrl}/codeTables`, params, { skipAuth: true });
  }

  public getPortalDomainCodeList() {
    const params = new HttpParams().set('code', 'PortalDomain');
    return this.http.get(`${environment.baseUrl}/codeTables`, params, { skipAuth: true });
  }

  public getStatusCodeList() {
    const params = new HttpParams().set('code', 'CompanyStatus');
    return this.http.get(`${environment.baseUrl}/codeTables`, params, { skipAuth: true });
  }

  public getRoleCodeList() {
    const params = new HttpParams().set('code', 'Role');
    return this.http.get(`${environment.baseUrl}/codeTables`, params, { skipAuth: true });
  }

  public getUserStatusCodeList() {
    const params = new HttpParams().set('code', 'UserStatus');
    return this.http.get(`${environment.baseUrl}/codeTables`, params, { skipAuth: true });
  }

  public getSalutationCodeList() {
    const params = new HttpParams().set('code', 'salutation');
    return this.http.get(`${environment.baseUrl}/codeTables`, params, { skipAuth: true });
  }

  public getCountryCodeList() {
    const params = new HttpParams().set('code', 'Country');
    return this.http.get(`${environment.baseUrl}/codeTables`, params, { skipAuth: true });
  }

  public getFeedTypeCodeList() {
    const params = new HttpParams().set('code', 'FeedType');
    return this.http.get(`${environment.baseUrl}/codeTables`, params, { skipAuth: true });
  }

  public getFeedClinicalCodeList() {
    const params = new HttpParams().set('code', 'FeedClinical');
    return this.http.get(`${environment.baseUrl}/codeTables`, params, { skipAuth: true });
  }

  public getIllnessCodeList() {
    const params = new HttpParams().set('code', 'MedicationIIlness');
    return this.http.get(`${environment.baseUrl}/codeTables`, params, { skipAuth: true });
  }

  public getVaccinationIllnessCodeList() {
    const params = new HttpParams().set('code', 'VaccinationIllness');
    return this.http.get(`${environment.baseUrl}/codeTables`, params, { skipAuth: true });
  }

  public getUnitCodeList() {
    const params = new HttpParams().set('code', 'MedicationUnit');
    return this.http.get(`${environment.baseUrl}/codeTables`, params, { skipAuth: true });
  }

  public getMethodCodeList() {
    const params = new HttpParams().set('code', 'VaccinMethode');
    return this.http.get(`${environment.baseUrl}/codeTables`, params, { skipAuth: true });
  }

  public getSalmonellaStatusCodeList() {
    const params = new HttpParams().set('code', 'SalmonellaStatus');
    return this.http.get(`${environment.baseUrl}/codeTables`, params, { skipAuth: true });
  }

  public getBlockingDateCode() {
    const params = new HttpParams().set('code', 'vkiBlocking');
    return this.http.get(`${environment.baseUrl}/codeTables`, params, { skipAuth: true });
  }

  public getMagazine() {
    return this.http.get(`${environment.baseUrl}/codeTables/magazine`, '');
  }

  public getQuestionnaireFrequencyCode() {
    const params = new HttpParams().set('code', 'questionnaireFrequency');
    return this.http.get(`${environment.baseUrl}/codeTables`, params, { skipAuth: true });
  }

  public getPeriodicStatusCode() {
    const params = new HttpParams().set('code', 'periodicStatus');
    return this.http.get(`${environment.baseUrl}/codeTables`, params, { skipAuth: true });
  }

  public getConceptsCode() {
    const params = new HttpParams().set('code', 'concepts');
    return this.http.get(`${environment.baseUrl}/codeTables`, params, { skipAuth: true });
  }

  public getCodeTableValue(code: string) {
    const params = new HttpParams().set('code', code);
    return this.http.get(`${environment.baseUrl}/codeTables`, params, { skipAuth: true });
  }

  public getNews(environmentId, selectedLang, all, name = null, fromDate = null, toDate = null) {
    if (all) {
      let params = new HttpParams().set('all', all);
      if (name) {
        params = params.append('name', name);
      }
      if (fromDate) {
        params = params.append('fromDate', fromDate);
      }
      if (toDate) {
        params = params.append('toDate', toDate);
      }

      return this.http.get(`${environment.baseUrl}/news/${environmentId}/${selectedLang}`, params);
    }

    return this.http.get(`${environment.baseUrl}/news/${environmentId}/${selectedLang}`, '');
  }

  public getHistoryNews(environmentId, selectedLang, all, name = null, fromDate = null, toDate = null, concept = null) {
    if (all) {
      let params = new HttpParams();
      if (name) {
        params = params.append('name', name);
      }
      if (fromDate) {
        params = params.append('fromDate', fromDate);
      }
      if (toDate) {
        params = params.append('toDate', toDate);
      }
      if (concept) {
        params = params.append('concept', concept);
      }

      return this.http.get(`${environment.baseUrl}/news/history/${environmentId}/${selectedLang}`, params);
    }

    return this.http.get(`${environment.baseUrl}/news/history/${environmentId}/${selectedLang}`, '');
  }

  public getConceptList() {
    return this.http.get(`${environment.baseUrl}/concept`, '');
  }

  public getDeliveryTypeCode() {
    const params = new HttpParams().set('code', 'DeliveryType');
    return this.http.get(`${environment.baseUrl}/codeTables`, params);
  }

  public getLastDeliveryCode() {
    const params = new HttpParams().set('code', 'LastDelivery');
    return this.http.get(`${environment.baseUrl}/codeTables`, params);
  }

  public getVkiStatusCode() {
    const params = new HttpParams().set('code', 'vkiStatus');
    return this.http.get(`${environment.baseUrl}/codeTables`, params);
  }

  public getApprovingCode() {
    const params = new HttpParams().set('code', 'Approving');
    return this.http.get(`${environment.baseUrl}/codeTables`, params);
  }

  public getUploadTimeCode() {
    const params = new HttpParams().set('code', 'UploadTime');
    return this.http.get(`${environment.baseUrl}/codeTables`, params);
  }

  public getTermAndConditionCodeList() {
    const params = new HttpParams().set('code', 'TermAndCondition');
    return this.http.get(`${environment.baseUrl}/codeTables`, params);
  }

  public getDocumentTypeCode() {
    const params = new HttpParams().set('code', 'Documenttype');
    return this.http.get(`${environment.baseUrl}/codeTables`, params);
  }

  public getSubjectCode() {
    const params = new HttpParams().set('code', 'Subject');
    return this.http.get(`${environment.baseUrl}/codeTables`, params);
  }

  public getReportTypeCode() {
    const params = new HttpParams().set('code', 'SalmonellaResearchType');
    return this.http.get(`${environment.baseUrl}/codeTables`, params);
  }

  public getCountryCode() {
    const params = new HttpParams().set('code', 'Country');
    return this.http.get(`${environment.baseUrl}/codeTables`, params);
  }

  public getSubTypeList() {
    const params = new HttpParams().set('code', 'reportSubType');
    return this.http.get(`${environment.baseUrl}/codeTables`, params);
  }

  public getStableLogTimer() {
    const params = new HttpParams().set('code', 'StableLog');
    return this.http.get(`${environment.baseUrl}/codeTables`, params);
  }

  public getWheatUse() {
    const params = new HttpParams().set('code', 'wheatUsage ');
    return this.http.get(`${environment.baseUrl}/codeTables`, params);
  }

  public getOtherDiseasesBE() {
    const params = new HttpParams().set('code', 'otherDiseasesBE');
    return this.http.get(`${environment.baseUrl}/codeTables`, params);
  }

  public getWaterUsage() {
    const params = new HttpParams().set('code', 'waterUsage');
    return this.http.get(`${environment.baseUrl}/codeTables`, params);
  }

  public getLitterType() {
    const params = new HttpParams().set('code', 'litterType');
    return this.http.get(`${environment.baseUrl}/codeTables`, params);
  }

  public getMyTasksTypeCode() {
    const params = new HttpParams().set('code', 'myTasksType');
    return this.http.get(`${environment.baseUrl}/codeTables`, params);
  }

  public getRelTypeBPLoc() {
    const params = new HttpParams().set('code', 'Rel Type BPLoc');
    return this.http.get(`${environment.baseUrl}/codeTables`, params, { skipAuth: true });
  }

  public getProteinOrigin() {
    const params = new HttpParams().set('code', 'proteinOrigin');
    return this.http.get(`${environment.baseUrl}/codeTables`, params);
  }
}
